@import "variables";

input[type="checkbox"].customized, input[type="radio"].customized {
  display: none;

  + label {
    position: relative;
    padding: 0px;
    display: flex;
    align-items: self-start;
    min-width: 25px;
    line-height: 20px;
    font-weight: 600;

    &:before, &:after {
      height: $checkboxSize;
      min-height: $checkboxSize;
      width: $checkboxSize;
      min-width: $checkboxSize;
      display: inline-block;
    }

    &:before {
      content: " ";
      position: relative;
      border: 1px solid $lightGray;
      margin-right: 5px;
    }

    &:after {
      line-height: $checkboxSize;
      text-align: center;
      font-size: 11px;
      animation: dropIn 0.3s ease;
      content: "\f00c";
      font-family: "Font Awesome 5 Pro";
      color: white;
      position: absolute;
      top: 0;
      left: 0;
      visibility: hidden;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &[type="radio"] + label {
    border-radius: 100%;

    &:before {
      border-radius: 100%;
    }
  }

  &:checked {
    + label {
      &:before {
        background-color: $main-color;
        border-color: $main-color;
      }

      &:after {
        visibility: visible;
      }
    }
  }

  &[disabled] {
    + label {
      &:before {
        background-color: lighten($main-color, 20%);
        border-color: lighten($main-color, 20%);
      }
    }
  }

}

@keyframes dropIn {
  0% {
    transform: scale(1.6);
  }
  100% {
    transform: scale(1);
  }
}
